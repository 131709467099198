<template>
    <div>
        <h1>Categories</h1>

        <div class="table-responsive">
            <table class="table table-striped table-hover table-borderless table-primary align-middle">
                <thead class="table-light">
                    <caption>
                        Table Name
                    </caption>
                    <tr>
                        <th>#</th>
                        <th>id</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Slug</th>
                        <th>Path</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody class="table-group-divider">
                    <tr v-for="(item, index) in categories" :key="index" class="table-primary">
                        <td scope="row">{{ index + 1 }}</td>
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.slug }}</td>
                        <td>{{ item.path }}</td>
                        <td>
                            <!--  Modal trigger button  -->
                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                :data-bs-target="'#modalId-' + item.id">
                                Edit
                            </button>
                            <!-- Modal Body-->
                            <div class="modal fade" :id="'modalId-' + item.id" tabindex="-1" role="dialog"
                                aria-labelledby="modalTitleId" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="modalTitleId">
                                                Category: {{ item.name }}
                                            </h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <form :ref="'category_update-' + item.id">
                                                <div class="mb-3">
                                                    <label for="exampleFormControlInput1"
                                                        class="form-label">Name</label>
                                                    <input type="email" class="form-control"
                                                        id="exampleFormControlInput1" placeholder="name@example.com"
                                                        v-model="item.name" name="name">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleFormControlInput1"
                                                        class="form-label">Status</label>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="status"
                                                            :id="'flexRadioDraft-'+item.id" value="DRAFT"
                                                            v-model="item.status" />
                                                        <label class="form-check-label w-100" :for="'flexRadioDraft-'+item.id">
                                                            DRAFT
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="status"
                                                            :id="'flexRadioPublished-'+item.id" value="PUBLISHED"
                                                            v-model="item.status" />
                                                        <label class="form-check-label w-100" :for="'flexRadioPublished-'+item.id">
                                                            PUBLISHED
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleFormControlInput1"
                                                        class="form-label">Slug</label>
                                                    <input type="email" class="form-control"
                                                        id="exampleFormControlInput1" placeholder="name@example.com"
                                                        v-model="item.slug" name="slug">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleFormControlTextarea1"
                                                        class="form-label">Path</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1"
                                                        rows="3" v-model="item.path" name="path"></textarea>
                                                </div>
                                            </form>

                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                                Close
                                            </button>
                                            <button type="button" class="btn btn-primary"
                                                @click="category_update(item.id)">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>

                </tfoot>
            </table>
        </div>




    </div>
</template>
<script>
import axios from "axios";

export default {
    data() {
        return {
            categories: []
        }
    },
    mounted() {
        this.getCategories()
    },
    methods: {
        getCategories() {
            const url = this.appDomainApi + "shops/product/category/index";
            axios.get(url,)
                .then(res => {
                    console.log(res.data)
                    this.categories = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        category_update(model_id) {
            console.log("category_update: " + model_id);

            let form = this.$refs['category_update-' + model_id][0];
            const formData = new FormData(form);

            // // Añadir el nombre del formulario al FormData
            // formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            formData.append('id', model_id);
            // console.log(...formData);
            // formData.append('slug', slug);

            const url = this.appDomainApi + "shops/product/category/update/" + model_id;

            axios.post(url, formData)
                .then(res => {
                    console.log(res)
                    this.categories = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
    },

}
</script>
<style scoped></style>